.ReviewsPage {


	.SocialReviews {
		min-height: calc(100vh - 9rem - 11rem);

		> .maxWidthContentWrapper > .content {
			height: calc(100vh - 37rem);
		}
	}
}
